const config = {
  Auth: {
    mandatorySignIn: true,
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_3UsrXgU1N",
    userPoolWebClientId: "6dba5hcm6el56niidig9do8376",
  },
  API: {
    endpoints: [
      {
        name: "Endpoint",
        endpoint:
          "https://nrujejy6g8.execute-api.ap-southeast-1.amazonaws.com/prod/",
        region: "ap-southeast-1",
      },
    ],
  },
};

export default config;
